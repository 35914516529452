import React, { CSSProperties } from "react";
import { CircleLoader } from "react-spinners";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "blue",
};

export default function Loader({ text }) {
  return (
    <div
      style={{
        backgroundColor: "#ffffffbb",
        zIndex: 10000,
        position: "fixed",
        height: "100%",
        width: "100%",
        left: "-0%",
        top: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backdropFilter: "blur(20px)",
      }}
    >
      <div>
        <CircleLoader
          cssOverride={override}
          size={70}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <p className="mt-4 text-gray-700">{text}</p>
      </div>
    </div>
  );
}
