import axios from "axios";

export function getRandomElements(arr, count) {
  let clonedArray = [...arr];

  for (let i = clonedArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [clonedArray[i], clonedArray[j]] = [clonedArray[j], clonedArray[i]];
  }

  return clonedArray.slice(0, count);
}

export const client = axios.create({
  withCredentials: true,
  baseURL: "http://styledbyecho.com/api/api/v1.0",
  // change to this when testing on local baseURL: "http://localhost:3001/api/v1.0",
});
