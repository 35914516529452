import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import icon1 from "../assets/icon1.svg";
import rose from "../assets/rose.svg";
import profile from "../assets/profile.png";

import { ThreeDots } from "react-loader-spinner";
import Search from "./Search";
import MessageInput from "./MessageInput";
import { useParams } from "react-router-dom";
import { client } from "../utils";
import Loader from "./Loader";

export default function Main({ chat }) {
  const urlParams = new URLSearchParams(window.location.search);
  const threadId = urlParams.get("threadId");
  const runId = urlParams.get("runId");
  const firstTime = urlParams.get("firstTime");
  const question = urlParams.get("question");
  let { retailerId } = useParams();

  const [messages, setMessages] = useState([
    {
      state: "ready",
      side: "bot",
      msg: "Hi, it’s me again. Based on your style preference, I have 3 outfit recommendations for you. ",
    },
  ]);
  const [session, setSession] = useState({});
  const [loading, setLoading] = useState(false);
  const [website, setWebsite] = useState("");
  const sendMessage = async (msg) => {
    setMessages([
      ...messages,
      { state: "ready", side: "user", msg },
      { state: "loading", side: "bot" },
    ]);

    try {
      const apiUrl = `/retailers/${retailerId}/new-message`;
      client
        .post(`${apiUrl}`, { threadId, message: msg, link: retailerId })
        .then((response) => {
          console.log(response.data.data.value);

          let text = purifyJsonText(response.data.data.value);
          let j = JSON.parse(text);
          updateMessage({
            state: "suggestion",
            content: j,
            side: "bot",
          });
        })
        .catch((error) => {
          console.log(error);
          console.error("Error:", error.response?.data || error.message);
        });
    } catch (err) {}
  };
  const updateMessage = (newData) => {
    setMessages((prevMessages) => {
      let temp = [...prevMessages];
      temp.pop();
      temp.push(newData);
      return temp;
    });
  };
  function purifyJsonText(text) {
    const regex = /```json([\s\S]*?)```/;
    const match = regex.exec(text);
    if (match && match[1]) {
      return match[1].trim();
    }
    return ""; // Return an empty string if no matching content is found
  }
  const handleBuyNow = async () => {
    const apiUrl = `/session/clicks`;
    client
      .put(`${apiUrl}`, {})
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        console.error("Error:", error.response?.data || error.message);
      });
  };
  const handleGetFirstResponse = async () => {
    try {
      // start measuring time here
      const apiUrl = `/retailers/${retailerId}/response?runId=${runId}&threadId=${threadId}`;
      client
        .get(`${apiUrl}`, {})
        .then((response) => {
          console.log(response.data.data.value);
          // stop measuring time
          let text = purifyJsonText(response.data.data.value);
          let j = JSON.parse(text);
          updateMessage({
            state: "suggestion",
            content: j,
            side: "bot",
          });
        })
        .catch((error) => {
          console.log(error);
          console.error("Error:", error.response?.data || error.message);
        });
    } catch (err) {}
  };

  const handleGetThreadMessages = async () => {
    try {
      const apiUrl = `/threads/${threadId}`;
      client
        .get(`${apiUrl}`, {})
        .then((response) => {
          const messages = response?.data?.data;
          messages.pop();
          messages.reverse();
          let temp = [];
          messages?.forEach((item) => {
            if (item.role == "user") {
              temp.push({
                state: "ready",
                side: "user",
                msg: item.content[0].text.value,
              });
            } else if (item.role == "assistant") {
              let text = purifyJsonText(item.content[0].text.value);
              let j = JSON.parse(text);
              temp.push({
                state: "suggestion",
                content: j,
                side: "bot",
              });
            }
          });
          setMessages(temp);
        })
        .catch((error) => {
          console.log(error);
          console.error("Error:", error.response?.data || error.message);
        });
    } catch (err) {}
  };

  const handleGetWebsite = async () => {
    try {
      console.log(retailerId);
      setLoading(true);
      client
        .get(`/retailers/${retailerId}/website`, {})
        .then((response) => {
          setWebsite(response?.data?.website);
        })
        .catch((error) => {
          console.error("Error:", error.response?.data || error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {}
  };
  const handleSession = async () => {
    try {
      setLoading(true);
      client
        .get(`/session`, {})
        .then((response) => {
          setSession(response.data);
        })
        .catch((error) => {
          console.error("Error:", error.response?.data || error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {}
  };

  useEffect(() => {
    handleGetWebsite();
    const session = handleSession();
    if (question) {
      setMessages([
        {
          state: "ready",
          side: "bot",
          msg: "Hi, it’s me again. What can I help you with today?",
          type: "options",
        },
      ]);
    }
    if (firstTime) {
      setTimeout(() => {
        setMessages([
          ...messages,
          {
            state: "ready",
            side: "bot",
            msg: "Let me look through your style option and see what will work best for you based on our inventory",
          },
          { state: "loading", side: "bot" },
        ]);
        handleGetFirstResponse();
      }, 1000);
    } else {
      handleGetThreadMessages();
    }
  }, []);

  useEffect(() => {
    if (messages?.length > 3) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [messages]);

  return (
    <div style={{ height: "1000px" }}>
      {loading && <Loader />}{" "}
      <Row className="h-100">
        <Col sm={3} className="sidebar p-6">
          <div className=" d-flex justify-content-between align-items-end">
            <h5>Chat History</h5>
            <img src={icon1} />
          </div>
          <Search />
          <div className="d-flex flex-column align-items-start">
            {session?.threads?.map((item, i) => {
              let j = purifyJsonText(item.messages[0]?.content[0]?.text.value);
              let threadId = item.messages[0]?.thread_id;
              let runId = item.messages[0]?.run_id;
              try {
                j = JSON.parse(j);
                return (
                  <a
                    className="my-1"
                    style={{ textDecoration: "none" }}
                    href={`/retailer/${retailerId}/chat?runId=${runId}&threadId=${threadId}&asistantId=${item.messages[0]?.assistantId}`}
                  >
                    {j?.outfits[0]?.items[0]?.description?.substring(0, 34) +
                      " ..."}
                  </a>
                );
              } catch (err) {}
            })}
          </div>
        </Col>
        <Col>
          <div className="header d-flex justify-content-between align-items-center">
            <h4 className="mt-4">Rose</h4>
            <div className="d-flex align-items-center">
              <a href={website} target="_blank">
                <div className="ml-auto shopping-btn ">Back To Shopping</div>
              </a>
            </div>
          </div>
          <div
            style={{ marginBottom: "200px" }}
            className="d-flex flex-column justify-content-end"
          >
            {messages.map((message) => {
              return (
                <div>
                  {message.side === "user" && (
                    <div>
                      {" "}
                      <div className="d-flex justify-content-end">
                        <div className="grey msg text-start">
                          {message.state === "ready" && <p>{message.msg}</p>}
                        </div>
                        <img
                          src={profile}
                          className="d-inline mt-3"
                          width={"80px"}
                          height={"70px"}
                        />
                      </div>
                    </div>
                  )}
                  {message.side === "bot" && (
                    <div className="d-flex ">
                      <img src={rose} className="d-inline" width={"50px"} />
                      <div className="grey msg text-start">
                        {message.state === "ready" && <p>{message.msg}</p>}
                        {message.state === "loading" && (
                          <ThreeDots
                            visible={true}
                            height="60"
                            width="60"
                            color="#888"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperClass=" mb-3"
                          />
                        )}
                        {message.state === "suggestion" && (
                          <div>
                            <p>{message.content?.text}</p>

                            {message?.content?.outfits?.map((outfit, i) => {
                              return (
                                <div key={i}>
                                  <div className="d-flex">
                                    {outfit?.items &&
                                      outfit?.items.length <= 2 &&
                                      outfit?.items.map((item, i) => {
                                        return (
                                          <div key={i} className="m-3 ">
                                            <h5>{item.type}</h5>
                                            <img
                                              src={item.image}
                                              width={"150"}
                                            />
                                            <a
                                              className="mt-2"
                                              target="_blank"
                                              onClick={() => {
                                                handleBuyNow();
                                              }}
                                              href={item.link}
                                            >
                                              Buy Now
                                            </a>
                                          </div>
                                        );
                                      })}
                                    {outfit?.items &&
                                      outfit?.items.length > 2 &&
                                      outfit?.items.length < 4 && (
                                        <div className="d-flex">
                                          <div key={i} className="m-1 mx-1 ">
                                            <h5>{outfit?.items[0].type}</h5>
                                            <img
                                              src={outfit?.items[0].image}
                                              width={"250"}
                                            />
                                            <a
                                              className="mt-1"
                                              target="_blank"
                                              onClick={() => {
                                                handleBuyNow();
                                              }}
                                              href={outfit?.items[0].link}
                                            >
                                              Buy Now
                                            </a>
                                          </div>
                                          <div className="">
                                            {outfit?.items.map((item, i) => {
                                              if (i === 1 || i === 2) {
                                                return (
                                                  <Row
                                                    key={i}
                                                    className=" mx-1 "
                                                  >
                                                    <Col>
                                                      <h5>{item.type}</h5>
                                                      <img
                                                        src={item.image}
                                                        width={"112"}
                                                      />
                                                      <a
                                                        className="mt-2"
                                                        target="_blank"
                                                        onClick={() => {
                                                          handleBuyNow();
                                                        }}
                                                        href={item.link}
                                                      >
                                                        Buy Now
                                                      </a>
                                                    </Col>
                                                  </Row>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    {outfit?.items &&
                                      outfit?.items.length > 3 && (
                                        <div className="d-flex">
                                          <div key={i} className="m-0 mx-1 ">
                                            <h5>{outfit?.items[0].type}</h5>
                                            <img
                                              src={outfit?.items[0].image}
                                              width={"250"}
                                            />
                                            <a
                                              className="mt-1"
                                              target="_blank"
                                              onClick={() => {
                                                handleBuyNow();
                                              }}
                                              href={outfit?.items[0].link}
                                            >
                                              Buy Now
                                            </a>
                                          </div>
                                          <div className="">
                                            {outfit?.items
                                              .slice(1, 3)
                                              .map((item, i) => {
                                                return (
                                                  <Row
                                                    key={i}
                                                    className=" mx-1 "
                                                  >
                                                    <Col>
                                                      <h5>{item.type}</h5>
                                                      <img
                                                        src={item.image}
                                                        width={"112"}
                                                      />
                                                      <a
                                                        className="mt-2"
                                                        target="_blank"
                                                        onClick={() => {
                                                          handleBuyNow();
                                                        }}
                                                        href={item.link}
                                                      >
                                                        Buy Now
                                                      </a>
                                                    </Col>
                                                  </Row>
                                                );
                                              })}
                                          </div>
                                          <div className="">
                                            {outfit?.items.map((item, i) => {
                                              if (i > 2) {
                                                return (
                                                  <Row
                                                    key={i}
                                                    className=" mx-1 "
                                                  >
                                                    <Col>
                                                      <h5>{item.type}</h5>
                                                      <img
                                                        src={item.image}
                                                        width={"112"}
                                                      />
                                                      <a
                                                        className="mt-1"
                                                        target="_blank"
                                                        onClick={() => {
                                                          handleBuyNow();
                                                        }}
                                                        href={item.link}
                                                      >
                                                        Buy Now
                                                      </a>
                                                    </Col>
                                                  </Row>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    <div className="p-4">
                                      <p>
                                        <strong>Item: </strong>
                                        {outfit?.items.map((d, i) => (
                                          <span key={i}>
                                            {d.description}
                                            <br></br>
                                          </span>
                                        ))}
                                      </p>
                                      <p>
                                        <strong>
                                          Pairs from your closet:{" "}
                                        </strong>
                                        {outfit?.misc}
                                      </p>

                                      <p>
                                        <strong>Style advice: </strong>
                                        {outfit?.styleAdvice}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {message?.type === "options" && (
                    <div className="float-left text-left bg-white">
                      <div
                        className="opt"
                        onClick={() => {
                          sendMessage(
                            "Put together an outfit for my brother's wedding"
                          );
                        }}
                      >
                        Put together an outfit for my brother's wedding{" "}
                      </div>
                      <div
                        className="opt"
                        onClick={() => {
                          sendMessage(
                            "Put together an outfit for a date night"
                          );
                        }}
                      >
                        Put together an outfit for a date night{" "}
                      </div>
                      <div
                        className="opt"
                        onClick={() => {
                          sendMessage(
                            "Put together a few options that are more timeless"
                          );
                        }}
                      >
                        Put together a few options that are more timeless{" "}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            <div
              className="bg-white pb-4 px-5 pt-2"
              style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                padding: "3px",
              }}
            >
              <MessageInput
                onSend={sendMessage}
                className="message-input"
              ></MessageInput>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
