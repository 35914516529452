import React from "react";
import zoom from "../assets/zoom.svg";

export default function Search({ children, onClick }) {
  return (
    <div>
      {" "}
      <div className=" d-flex justify-content-start align-items-end my-3 field-out">
        <img src={zoom} role="button" />
        <input className="field-in" placeholder="Type a message"></input>
      </div>
    </div>
  );
}
