import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Main from "./components/Main";
import Init from "./components/Init";
import Dashboard from "./components/Dashboard";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/retailer/:retailerId/:sessionId/chat"
            element={<Main />}
          ></Route>

          <Route path="/retailer/:retailerId/chat" element={<Main />}></Route>

          <Route path="/retailer/:retailerId" element={<Init />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
