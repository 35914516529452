export const men =  [
  {
    gender: "m",
    image: "/styleImages/m1.png",
    label:
      "A stylish figure on a city pavement. The individual is dressed in a luxurious black shirt, loosely buttoned and paired with flowing cream trousers that contrast sharply with the dark top. Sleek black loafers with gold accents complete the look, striking a balance between relaxed and refined.",
  },
  {
    gender: "m",
    image: "/styleImages/m2.png",
    label:
      "This image presents a portrait of a person exuding effortless elegance on a city street. They wear a layered outfit consisting of a crisp white collared shirt beneath a warm grey sweater, paired with dark trousers. Holding a coffee cup, their thoughtful gaze and the sophisticated color palette of their attire convey a cosmopolitan sense of style",
  },
  {
    gender: "m",
    image: "/styleImages/m3.png",
    label:
      "The image shows a playful and casual street style. Here, an individual flexes their arm in a show of strength, wearing a cropped white graphic t-shirt that reveals a fit physique, paired with light-wash denim jeans. The outfit is complemented by a black belt, adding a touch of classic cool to the laid-back look",
  },
  {
    gender: "m",
    image: "/styleImages/m4.png",
    label:
      "We see a moment of urban leisure. A person stands against a warm-toned building, donning a simple yet refined ensemble featuring a cream knitted sweater and black trousers. They complete their look with a black baseball cap and white headphones, sipping a beverage, embodying a relaxed, modern lifestyle",
  },

  {
    gender: "m",
    image: "/styleImages/m5.png",
    label:
      "Image depicts a fashion-forward scene on an urban street. The focal point is a person sporting a loose-fitting, striped blue shirt, left open for a relaxed vibe over a white tee, paired with light beige trousers. The outfit is accessorized with a dark green baseball cap, a white beaded necklace, and a cream tote bag, blending casual comfort with a dash of urban sophistication",
  },
  {
    gender: "m",
    image: "/styleImages/m6.png",
    label:
      "Image portrays a model exuding confidence in a tailored, monochromatic outfit. The ensemble is composed of a finely woven brown turtleneck paired with a matching oversized blazer and trousers, cut to drape effortlessly. The attire is meticulously styled to convey a modern, yet timeless aesthetic. Completing the look are dark, round sunglasses, lending an air of mystery and cosmopolitan flair",
  },
  {
    gender: "m",
    image: "/styleImages/m7.png",
    label:
      "image captures a stylish individual in a coastal setting. They are dressed in a relaxed yet chic ensemble, featuring a navy baseball cap, a white shirt left casually unbuttoned to reveal a light blue undershirt, and dark shorts that complement the overall look. Accessories include a prominent tan leather backpack, which adds a touch of sophistication. The backdrop is a picturesque view of the sea, framed by lush greenery and a clear blue sky, evoking a sense of leisure and escape",
  },
  {
    gender: "m",
    image: "/styleImages/m8.png",
    label:
      "A monochromatic image captures a person dressed in vintage tennis whites, poised in mid-motion with a ball and racquet. The classic polo shirt, adorned with an emblem, and the neat haircut convey a timeless sporting elegance, reminiscent of an earlier era of sportsmanship These descriptions are crafted to vividly convey the style and atmosphere of each image, providing a clear understanding of the aesthetic and mood that could be used for style curation or mood board creation",
  },
  {
    gender: "m",
    image: "/styleImages/m9.png",
    label:
      "The photo features a person in a long, plaid overcoat with a high-necked camel sweater underneath, paired with cream trousers. They hold a leather bag, and their poised demeanor against a neutral backdrop highlights the ensemble's luxurious textures and autumnal color palette",
  },
  {
    gender: "m",
    image: "/styleImages/m10.png",
    label:
      "A person stands by a vintage vehicle, exuding an air of classic sophistication. They wear a leather jacket over a cream cable-knit sweater, with a patterned scarf peeking from the collar. Paired with dark trousers and brown leather gloves, the look is reminiscent of an elegant bygone era.",
  },
  {
    gender: "m",
    image: "/styleImages/m11.png",
    label:
      "This image captures a maritime theme, with a person on a wooden sailboat deck, wearing a cream sweater and navy shorts. The nautical vibe is enhanced by their casual stance and the harbor in the background, suggesting a connection to the sea and sailing heritage.",
  },
  {
    gender: "m",
    image: "/styleImages/m12.png",
    label:
      "Here we see a person posing confidently in a modern, yet timeless ensemble. Their bold mustard-colored shirt jacket is worn over a textured white knit, paired with taupe trousers and white sneakers. A grey beanie and glasses complete the look, which blends vibrant color with understated style",
  },
  {
    gender: "m",
    image: "/styleImages/m13.png",
    label:
      "The photo depicts a relaxed moment in an urban garden setting. A person is seated at a wooden table, wearing a black crewneck and matching trousers, offset with white socks and rugged sandals. The act of sipping from a mug amidst lush greenery evokes a serene, introspective mood",
  },
  {
    gender: "m",
    image: "/styleImages/m14.png",
    label:
      "A portrait of polished elegance, the image features a person in a rich brown velvet blazer layered over a crisp white shirt, paired with light beige trousers. Accessories like a gold watch and rings add a touch of opulence. The ensemble is a blend of vintage charm and contemporary fashion sensibilities",
  },
  {
    gender: "m",
    image: "/styleImages/m15.png",
    label:
      "The image shows a person taking a mirror selfie in a chic, minimalist interior. They're wearing a white graphic hoodie and black slim-fit trousers, paired with crisp white sneakers. The checkerboard floor adds a classic touch to the modern aesthetic, while the tall sticks in a vase contribute to the room's refined ambiance",
  },
  {
    gender: "m",
    image: "/styleImages/m16.png",
    label:
      "A picturesque outdoor dining scene captures the essence of a Mediterranean idyll. A table set for a sumptuous breakfast is laden with local delicacies, fresh pastries, and fruits, while a carafe of orange juice catches the morning light. Overhead, a vine-covered pergola provides dappled shade, and the distant hills offer a serene backdrop. The scene is an invitation to a slow and indulgent start to the day",
  },
  {
    gender: "m",
    image: "/styleImages/m17.png",
    label:
      "A mirror selfie reveals a person's outfit consisting of a cream turtleneck and a long, oversized brown coat with matching trousers. The ensemble, completed with polished black leather boots, exudes a blend of classic tailoring and contemporary minimalism",
  },
  {
    gender: "m",
    image: "/styleImages/m18.png",
    label:
      "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
  },
];


export const women = [
    {
        gender: "f",
        image: "/styleImages/f1.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f2.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f3.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f4.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f5.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f6.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f7.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f8.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f9.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f10.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f11.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f12.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f13.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f14.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },

      {
        gender: "f",
        image: "/styleImages/f16.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f17.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f18.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f19.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f20.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f21.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f22.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f23.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      },
      {
        gender: "f",
        image: "/styleImages/f24.png",
        label:
          "An artistic shot focuses on a swimmer's legs emerging from the shimmering sea, capturing the sparkling droplets of water in motion. The image conveys the refreshing tranquility and weightlessness of a serene dip in calm waters",
      }
]