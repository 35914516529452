import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { client } from "../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Main() {
  const [authenticated, setAuthenticated] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ email: "", name: "", password: "" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [me, setMe] = useState({});
  const [file, setFile] = useState(null);
  useEffect(() => {
    const handleEffect = async () => {
      const res = await fetchSelf();
      if (res) {
        setAuthenticated(true);
        fetchUsers();
      }
    };
    handleEffect();
  }, []);

  const fetchSelf = async () => {
    try {
      const res = await client.get("/users/self");
      if (res.status === 200) {
        setMe(res.data);
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };
  const fetchUsers = async () => {
    const res = await client.get("/users");
    console.log(res.data);
    setUsers(res.data);
  };

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };
  const addUser = async (e) => {
    const res = await client.post("/users/register", newUser);
    window.location.reload();
  };

  const handleLogin = async () => {
    try {
      const res = await client.post("/login", { email, password });
      setAuthenticated(true);
      window.location.reload();
    } catch (err) {}
  };
  const handleFileUpload = async () => {
    const formData = new FormData();
    if (!!file) {
      formData.append("file", file);
      const res = await client.put("/users/update-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        toast.success("File Uploaded Successfully");
      }
      return res;
    }
  };
  return (
    <div style={{ height: "1000px" }}>
      <ToastContainer />

      {!authenticated && (
        <div>
          <Row>
            <Col>
              <div className="w-70 px-5 text-start mt-4">
                <Form>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label> Email</Form.Label>
                    <Form.Control
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="Please Enter Email"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label> Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      placeholder="Please Enter Password"
                    />
                  </Form.Group>
                  <Button
                    className="mt-4"
                    onClick={() => {
                      handleLogin();
                    }}
                  >
                    {" "}
                    Login
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      )}

      {authenticated && me?.rank < 6 && (
        <div>
          <Row className="mt-4 text-start pl-4">
            <Col className="text-start pl-4">
              <h3>Upload Inventory File</h3>
              <input
                type="file"
                className="mt-4 ml-4"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
              <Button className="mt-4 ml-4" onClick={handleFileUpload}>
                Save{" "}
              </Button>
            </Col>
            <Col>
              <h3>Stats</h3>
              <p> Total Interactions: 5 </p>
              <p> Total Users: 2 </p>
              <p> Total Messages: 10 </p>
            </Col>
          </Row>
        </div>
      )}

      {authenticated && me?.rank == 6 && (
        <div>
          <Row>
            <Col>
              <div className="w-70 px-5 text-start mt-4">
                <Form>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label> Email</Form.Label>
                    <Form.Control
                      name="email"
                      value={newUser.email}
                      onChange={handleInputChange}
                      placeholder="Please Enter Email"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label> Name</Form.Label>
                    <Form.Control
                      name="name"
                      value={newUser.name}
                      onChange={handleInputChange}
                      placeholder="Please Enter Name"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label> Website</Form.Label>
                    <Form.Control
                      name="website"
                      value={newUser.website}
                      onChange={handleInputChange}
                      placeholder="Please Enter Website"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 text-start">
                    <Form.Label> Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      value={newUser.password}
                      onChange={handleInputChange}
                      placeholder="Please Enter Password"
                    />
                  </Form.Group>
                  <Button
                    className="mt-4"
                    onClick={() => {
                      addUser();
                    }}
                  >
                    {" "}
                    ADD USER
                  </Button>
                </Form>
              </div>
            </Col>
            <Col>
              <div className="w-70 px-5">
                <Table bordered hover className="mt-5">
                  <thead>
                    <tr>
                      <th> Name</th>
                      <th>Email</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={index}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>
                          <a
                            style={{ fontSize: "10px" }}
                            href={`/retailer/${user.link}/`}
                          >
                            {user.link}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
