import React, { useState } from "react";
import go from "../assets/go.svg";
export default function MessageInput({ onSend }) {
  const [message, setMessage] = useState("");
  return (
    <div className="message-input text-start d-flex">
      <input
        className="field-in w-80"
        placeholder="Type a message"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <div
        className="go-btn"
        onClick={() => {
          setMessage("");
          onSend(message);
        }}
      >
        <img src={go} role="button" width={"20px"} />
      </div>
    </div>
  );
}
