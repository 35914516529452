import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import people from "../assets/people.svg";
import bags from "../assets/bags.svg";
import rose from "../assets/rose.svg";
import back from "../assets/back.svg";
import troller from "../assets/troller.svg";
import bagss from "../assets/bags.svg";
import items from "../assets/items.svg";
import page from "../assets/page.svg";
import page2 from "../assets/page2.svg";
import { men, women } from "./styles";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { client, getRandomElements } from "../utils";
import { ToastContainer, toast } from "react-toastify";
export default function Main() {
  const [step, setStep] = useState(0);
  const [generalStep, setGeneralStep] = useState(0);
  const [welcomeAnimation, setWelcomeAnimation] = useState(false);
  const windowSize = window.innerHeight + "px";
  const messageList = [
    "Hi there! My name is Rose and I'm your AI-powered stylist.",
    "In just a few taps, I can help you with...",
    "Discovering the latest pieces that fit your style",
    "Putting together outfits based on basics you in your closet",
    "Getting personalized style recommendations",
  ];
  const navigate = useNavigate();
  let { retailerId } = useParams();
  const [messages, setMessages] = useState([]);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [styles, setStyles] = useState([]);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedIntent, setSelectedIntent] = useState("");
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [celebrity, setCelebrity] = useState("");
  const [selectedStyleTypes, setSelectedStyleTypes] = useState([]);
  const [chatData, setChatData] = useState({});
  const [navLink, setNavLink] = useState();
  const questionsCount = 6;
  const colors = [
    { name: "Black", value: "#000000" },
    { name: "Blue", value: "#0000FF" },
    { name: "Brown", value: "#A52A2A" },
    { name: "Green", value: "#008000" },
    { name: "Grey", value: "#808080" },
    { name: "Khaki", value: "#F0E68C" },
    { name: "Sky", value: "#87CEEB" },
    { name: "Navy", value: "#000080" },
    { name: "Olive", value: "#808000" },
    { name: "Pink", value: "#FFC0CB" },
    { name: "Purple", value: "#800080" },
    { name: "Red", value: "#FF0000" },
    { name: "Salmon", value: "#FA8072" },
    { name: "White", value: "#eeeeee" },
    { name: "Yellow", value: "#FFFF00" },
  ];
  const styleTypes = [
    "Casual",
    "Streetwear",
    "Formal",
    "Quirky",
    "Vintage",
    "Minimalist",
    "Eccentric",
    "Trendy",
    "Timeless",
  ];
  const handleStyleTypeClick = (styleType) => {
    setSelectedStyleTypes((prevTypes) =>
      prevTypes.includes(styleType)
        ? prevTypes.filter((type) => type !== styleType)
        : [...prevTypes, styleType]
    );
  };
  const handleColorClick = (colorName) => {
    setSelectedColors((prevColors) =>
      prevColors.includes(colorName)
        ? prevColors.filter((color) => color !== colorName)
        : [...prevColors, colorName]
    );
  };

  const addRoseMessages = () => {
    let i = 0;
    let intId = setInterval(() => {
      setMessages((prevMessages) => [...prevMessages, messageList[i - 1]]);
      i++;
      if (i == 5) {
        setGeneralStep(1);
        setTimeout(() => {
          setGeneralStep(2);
        }, 2000);
        clearInterval(intId);
      }
    }, 1500);
  };
  const handleRunBot = () => {
    const userStyleAttributes = {
      gender: selectedGender,
      unwantedColors: selectedColors,
      celebrities: celebrity,
      styleMoods: selectedStyles,
      styleTypes: selectedStyleTypes,
    };
    const apiUrl = `/retailers/${retailerId}/new-chat`;
    client
      .post(`${apiUrl}`, userStyleAttributes)
      .then((response) => {
        setNavLink(
          `chat?runId=${response.data.runId}&threadId=${response.data.threadId}&assistantId=${response.data.assistantId}&firstTime=true`
        );
      })
      .catch((error) => {
        console.log(error);
        console.error("Error:", error.response?.data || error.message);
      });
  };

  useEffect(() => {
    if (selectedGender === "male") {
      setStyles(getRandomElements(men, 9));
    } else if (selectedGender === "female") {
      setStyles(getRandomElements(women, 9));
    } else {
      const temp = women.concat(men);
      setStyles(getRandomElements(temp, 9));
    }
  }, [selectedGender]);
  return (
    <div style={{ height: windowSize }}>
      <ToastContainer />
      {generalStep === 3 && (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <div>
            <h1>
              Analyzing <span className="brown">Your Style</span>
            </h1>
            <div
              className="loading-bar"
              onAnimationEndCapture={() => {
                navigate(navLink);
              }}
            ></div>
          </div>
        </div>
      )}
      {generalStep === 0 && (
        <Row className="h-100">
          <Col
            sm={12}
            xs={12}
            md={12}
            lg={3}
            className=" p-5 d-flex flex-column align-items-between justify-content-center"
          >
            {step === 0 && (
              <div
                className={`${welcomeAnimation ? "fade-out" : ""} `}
                onAnimationEnd={() => {
                  setStep(1);
                  setTimeout(() => addRoseMessages(), 1000);
                }}
                style={{ marginBottom: "auto", marginTop: "10rem" }}
              >
                <h1>Welcome!</h1>
                <p style={{ textAlign: "left" }}>
                  Find your favorite pieces with our AI powered chatbot
                </p>
                <div
                  className="start-btn m-5"
                  role="button"
                  onClick={() => {
                    setWelcomeAnimation(true);
                  }}
                >
                  Get Started
                </div>
              </div>
            )}

            {step === 1 && (
              <div className="d-flex flex-column align-items-start fade-in cascade">
                <h1>
                  Meet <span className="brown">Rose</span>{" "}
                </h1>
                <p style={{ textAlign: "left" }}>
                  Find your favorite pieces with our AI powered chatbot
                </p>
              </div>
            )}
            {step === 2 && (
              <div className="d-flex flex-column justify-content-between h-100">
                <div></div>
                <div></div>

                <div>
                  <h1 style={{ textAlign: "left" }}>Style Preferences</h1>
                  <p style={{ textAlign: "left" }}>
                    Tell us more about your style!
                  </p>
                </div>
                <img
                  src={bags}
                  className="mt-5 ml-auto"
                  style={{ transform: "scale(0.8)", left: "0px" }}
                />
              </div>
            )}
          </Col>
          <Col className="grey">
            {step === 0 && (
              <div className="m-5">
                <img src={people} style={{ maxWidth: "100%" }} />
                <h4 className="mt-3" style={{ fontWeight: "400" }}>
                  Let our virtual stylist help you find the{" "}
                  <span className="brown">perfect look</span> from our inventory
                </h4>
              </div>
            )}
            {step === 1 && (
              <div
                className={
                  "h-100 d-flex flex-column go-up-left justify-content-start align-items-start"
                }
              >
                {messages.map((m, i) => {
                  return (
                    <div
                      className="d-flex justify-content-start align-items-center mt-4 fade-in "
                      key={i}
                    >
                      <img src={rose} className="mx-3" height={"50px"} />
                      <div className="message-light">{m}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      )}
      {generalStep === 1 && (
        <div
          style={{ height: windowSize }}
          className="grey d-flex justify-content-center align-items-center"
        >
          {" "}
          <h1 style={{ fontWeight: 400 }}>
            Lets <span className="brown">Dive in...</span>
          </h1>
        </div>
      )}

      {generalStep === 2 && (
        <Row className="h-100 p-3 pt-0">
          <Col
            sm={12}
            xs={12}
            md={12}
            lg={3}
            className="p-5 d-flex flex-column align-items-between justify-content-center grey overflow-hidden"
            style={{ height: window.innerHeight < 700 ? "180px" : "relative" }}
          >
            <div></div>
            <div style={{ marginBottom: "auto", marginTop: "10rem" }}>
              <div>
                <h1 style={{ textAlign: "left" }}>Style Preferences</h1>
                <p style={{ textAlign: "left" }}>
                  Tell us more about your style!
                </p>
              </div>
              <div>
                {currentQuestionNumber === 0 && (
                  <img
                    src={bags}
                    className="mt-5 ml-auto"
                    style={{ transform: "scale(0.8)", left: "0px" }}
                  />
                )}
                {currentQuestionNumber === 1 && (
                  <img
                    src={troller}
                    className="mt-5 ml-auto"
                    style={{ transform: "scale(0.8)", left: "0px" }}
                  />
                )}

                {currentQuestionNumber === 2 && (
                  <img
                    src={page}
                    className="mt-5 ml-auto"
                    style={{ transform: "scale(0.8)", left: "0px" }}
                  />
                )}
                {currentQuestionNumber === 3 && (
                  <img
                    src={bagss}
                    className="mt-5 ml-auto"
                    style={{ transform: "scale(0.8)", left: "0px" }}
                  />
                )}
                {currentQuestionNumber === 4 && (
                  <img
                    src={page2}
                    className="mt-5 ml-auto"
                    style={{ transform: "scale(0.8)", left: "0px" }}
                  />
                )}
                {currentQuestionNumber === 5 && (
                  <img
                    src={items}
                    className="mt-5 ml-auto"
                    style={{ transform: "scale(0.8)", left: "0px" }}
                  />
                )}
              </div>
            </div>
          </Col>

          <Col className="  ">
            <div className="p-2">
              {<div className="vs-10"></div>}{" "}
              <div className="d-flex justify-content-start align-items-center">
                <div style={{ width: 100, height: 100, pathColor: "green" }}>
                  <CircularProgressbar
                    value={parseInt(
                      (currentQuestionNumber * 100) / questionsCount
                    )}
                    text={`${parseInt(
                      (currentQuestionNumber * 100) / questionsCount
                    )}%`}
                    styles={buildStyles({
                      // Rotation of path and trail, in number  of turns (0-1)
                      rotation: 0.25,
                      textColor: "black",
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      trailColor: `#F2F2F2`,
                      pathColor: "#9d853c",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
                <div className="mx-4 text-start" style={{ maxWidth: "42rem" }}>
                  <h1 style={{ fontWeight: 400 }}>
                    {currentQuestionNumber === 0 &&
                      "What are you looking for today"}
                    {currentQuestionNumber === 1 &&
                      "How would you describe your style preference?"}
                    {currentQuestionNumber === 2 &&
                      "Choose images that resonate with your fashion sense."}
                    {currentQuestionNumber === 3 &&
                      `Who inspires your style?
                      (influencers, celebrities, etc)`}
                    {currentQuestionNumber === 4 &&
                      "Are there any colors you typically avoid?"}
                    {currentQuestionNumber === 5 &&
                      "Last question! How would you describe your personal style?"}
                  </h1>
                </div>
              </div>
              {currentQuestionNumber === 0 && (
                <div className="mt-4 " style={{ maxWidth: "42rem" }}>
                  <div
                    className="answer mb-2"
                    onClick={(e) => {
                      setSelectedIntent(
                        "want a personalized styling experience"
                      );
                      setCurrentQuestionNumber(1);
                    }}
                  >
                    I want a personalized styling experience
                  </div>
                  <div
                    className="answer mb-2"
                    onClick={(e) => {
                      setSelectedIntent(
                        "have a specific question or want targeted advice"
                      );
                      navigate("chat?question=true");
                    }}
                  >
                    I have a specific question or want targeted advice
                  </div>
                </div>
              )}
              {currentQuestionNumber === 2 && (
                <Row style={{ maxWidth: "42rem" }} className="d-flex">
                  {styles?.map((style, i) => {
                    return (
                      <Col
                        className={`mt-4 style-img${
                          selectedStyles.includes(style) ? "-selected" : ""
                        } mx-2 `}
                        xs={3}
                        role="button"
                        onClick={() => {
                          if (selectedStyles.includes(style)) {
                            setSelectedStyles((prevStyles) =>
                              prevStyles.filter((s) => s !== style)
                            );
                          } else {
                            setSelectedStyles((prevStyles) => [
                              ...prevStyles,
                              style,
                            ]);
                          }
                        }}
                      >
                        <img
                          src={style.image}
                          width={"180px"}
                          height={"250px"}
                          style={{ marginLeft: "-1rem" }}
                        />
                      </Col>
                    );
                  })}

                  {/* <div
                    className="mt-4 style-img mx-2"
                    onClick={(e) => {
                      const newAnswers = [...answers];
                      newAnswers[2] = "dress";
                      setAnswers(newAnswers);
                      setCurrentQuestionNumber(3);
                    }}
                  >
                    <img src={womanDress} />
                  </div> */}
                  {/* <div
                    className="mt-4 style-img mx-2"
                    onClick={(e) => {
                      const newAnswers = [...answers];
                      newAnswers[2] = "full";
                      setAnswers(newAnswers);
                      setCurrentQuestionNumber(3);
                    }}
                  >
                    <img src={womanFull} />
                  </div> */}
                </Row>
              )}
              {currentQuestionNumber === 3 && (
                <div className="mt-5 " style={{ maxWidth: "42rem" }}>
                  <div className="custom-input text-start d-flex mt-4">
                    <input
                      className="field-in w-80"
                      placeholder="Your style influences"
                      onChange={(e) => {
                        setCelebrity(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
              {currentQuestionNumber === 5 && (
                <div
                  className="mt-5 d-flex  flex-wrap"
                  style={{ maxWidth: "42rem" }}
                >
                  {styleTypes.map((styleType) => (
                    <div
                      key={styleType}
                      className={`pick-btn ${
                        selectedStyleTypes.includes(styleType)
                          ? "pick-btn-selected"
                          : ""
                      }`}
                      onClick={() => {
                        handleStyleTypeClick(styleType);
                      }}
                    >
                      {styleType}
                    </div>
                  ))}
                </div>
              )}
              {currentQuestionNumber === 4 && (
                <div className="mt-5 " style={{ maxWidth: "42rem" }}>
                  <Row className="m-5">
                    {colors.map((color) => (
                      <Col xs={4} md={3} key={color.name}>
                        <div
                          className={`pick-color ${
                            selectedColors.includes(color.name)
                              ? `pick-color-selected`
                              : ""
                          }`}
                          onClick={() => handleColorClick(color.name)}
                          style={{ backgroundColor: color.value }}
                        >
                          {color.name}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              )}
              {currentQuestionNumber === 1 && (
                <div className="mt-4 " style={{ maxWidth: "42rem" }}>
                  <div
                    className="answer mb-2"
                    onClick={(e) => {
                      setSelectedGender("male");
                      setTimeout(() => {
                        setCurrentQuestionNumber(2);
                      }, 20);
                    }}
                  >
                    Masculine
                  </div>
                  <div
                    className="answer mb-2"
                    onClick={(e) => {
                      setSelectedGender("female");
                      setTimeout(() => {
                        setCurrentQuestionNumber(2);
                      }, 20);
                    }}
                  >
                    Feminine
                  </div>

                  <div className="mt-5 d-flex justify-content-between"></div>
                </div>
              )}
              <div
                className="d-flex justify-content-between mt-4"
                style={{ maxWidth: "42rem" }}
              >
                {currentQuestionNumber > 0 && (
                  <div
                    className="back-btn"
                    onClick={(e) => {
                      setCurrentQuestionNumber((prev) => prev - 1);
                    }}
                  >
                    <img src={back} />
                  </div>
                )}
                {currentQuestionNumber == 0 && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "#777",
                      marginTop: "0.4rem",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setCurrentQuestionNumber((prev) => prev + 1);
                    }}
                  >
                    NEXT
                  </div>
                )}

                {currentQuestionNumber == 2 && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "#777",
                      marginTop: "0.4rem",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      console.log(selectedStyles);
                      if (selectedStyles.length == 0) {
                        toast.error("You need to select styles");
                        return;
                      } else {
                        setCurrentQuestionNumber((prev) => prev + 1);
                      }
                    }}
                  >
                    NEXT
                  </div>
                )}
                {currentQuestionNumber == 3 && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "#777",
                      marginTop: "0.4rem",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setCurrentQuestionNumber((prev) => prev + 1);
                    }}
                  >
                    NEXT
                  </div>
                )}
                {currentQuestionNumber == 4 && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "#777",
                      marginTop: "0.4rem",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      if (selectedColors.length == 0) {
                        toast.error("You need to select at least one color");
                        return;
                      } else {
                        handleRunBot();
                        setCurrentQuestionNumber((prev) => prev + 1);
                      }
                    }}
                  >
                    NEXT
                  </div>
                )}
                {currentQuestionNumber == 5 && (
                  <div
                    style={{
                      fontSize: 12,
                      color: "#777",
                      marginTop: "0.4rem",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      setGeneralStep(3);
                    }}
                  >
                    NEXT
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
